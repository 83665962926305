import './App.less';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { notification } from 'antd';

import { useStore } from 'effector-react';
import { forgotSession, SystemStore } from './effector/system';
import { fetchAppointments, FilterStore } from './effector/appointments';

import { PrivateRoute } from './router/PrivateRoute';
import { FeaturedLayout } from './containers/FeaturedLayout';

import { Loader } from './components/Loader';

import { Login } from './views/Login';
import { Appointments } from './views/Appointments';

const RootPath = process.env.PUBLIC_URL || '/';

const App: FunctionComponent = () => {
    const filters = useStore(FilterStore);
    const { loggedIn } = useStore(SystemStore);
    const logoutPending = useStore(forgotSession.pending);
    const appointmentsPending = useStore(fetchAppointments.pending);

    const [listFetched, setListFetched] = useState(false);

    const loading = useMemo(
        () => logoutPending || (!listFetched && appointmentsPending),
        [listFetched, logoutPending, appointmentsPending]
    );

    useEffect(
        () => {
            if (loggedIn) {
                fetchAppointments(filters)
                    .catch((e) =>
                        notification.error({
                            message: 'Ошибка',
                            description: e.getCommonFirstMessage(),
                        })
                    )
                    .finally(() => setListFetched(true));
            }
        },
        [loggedIn] // eslint-disable-line react-hooks/exhaustive-deps
    );

    if (loading)
        return (
            <FeaturedLayout>
                <Loader />
            </FeaturedLayout>
        );

    return (
        <Router basename={RootPath}>
            <Switch>
                <Route path="/" exact>
                    {loggedIn ? <Redirect to="/appointments" /> : <Login />}
                </Route>

                <PrivateRoute path="/appointments/:id?">
                    <Appointments />
                </PrivateRoute>

                <Route path="*" exact>
                    <Redirect to="/appointments" />
                </Route>
            </Switch>
        </Router>
    );
};

const app = App;
export { app as App };
