import { createDomain } from 'effector';
import dayjs from 'dayjs';

import { fromTraversable, Lens } from 'monocle-ts';
import { array } from 'fp-ts/es6/Array';
import { pipe } from 'fp-ts/es6/function';

import { ApiError } from '../api/client/errors';
import { Protocol } from '../api/protocol';
import {
    apiAppointmentsList,
    apiFinishVideoChat,
    apiStartVideoChat,
} from '../api';
import { clearSession } from './system';

const AppointmentsDomain = createDomain();

export const clearAppointments = AppointmentsDomain.event();

export const setFilters = AppointmentsDomain.event<
    Protocol.AppointmentDoctorListRequest
>();
export const clearFilters = AppointmentsDomain.event();

export const startVideoChat = AppointmentsDomain.effect<
    Protocol.AppointmentStartVideoChatRequest,
    Protocol.AppointmentStartVideoChatResponse,
    ApiError
>();

export const finishVideoChat = AppointmentsDomain.effect<
    Protocol.AppointmentFinishVideoChatRequest,
    Protocol.AppointmentFinishVideoChatResponse,
    ApiError
>();

export const fetchAppointments = AppointmentsDomain.effect<
    Protocol.AppointmentDoctorListRequest,
    Protocol.AppointmentDoctorListResponse,
    ApiError
>();

startVideoChat.use(apiStartVideoChat);
finishVideoChat.use(apiFinishVideoChat);
fetchAppointments.use(apiAppointmentsList);

export type AppointmentsState = Protocol.Appointment[];
const initialState: AppointmentsState = [];

const listTraversable = fromTraversable(array)<Protocol.Appointment>();
const appointmentVideoLens = Lens.fromProps<Protocol.Appointment>()([
    'patient_name',
    'video_chat_token',
    'room_name',
]);

export const AppointmentsStore = AppointmentsDomain.store(initialState)
    .on(fetchAppointments.doneData, (_, { items }) => items)
    .on(startVideoChat.done, (state, payload) =>
        pipe(
            state,
            listTraversable
                .filter((app) => app.id === payload.params.appointment_id)
                .composeLens(appointmentVideoLens)
                .set({
                    room_name: payload.result.room_name,
                    patient_name: payload.result.patient_name,
                    video_chat_token: payload.result.token,
                })
        )
    )
    .reset(clearAppointments, clearSession);

export type FiltersState = Protocol.AppointmentDoctorListRequest;
const now = dayjs().format('YYYY.MM.DD') as Protocol.Types.Date;
const initialFilterState: FiltersState = { date_from: now, date_to: now };
export const FilterStore = AppointmentsDomain.store(initialFilterState)
    .on(setFilters, (_, payload) => payload)
    .reset(clearFilters, clearSession);

const updateAppointments = (): void => {
    fetchAppointments(FilterStore.getState());
};

AppointmentsStore
    .on(startVideoChat.done, updateAppointments)
    .on(finishVideoChat.done, updateAppointments);
