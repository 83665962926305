import { ReactNode } from 'react';

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

import { Protocol } from '../api/protocol';

dayjs.extend(calendar);

export const showCalendarDate = (dateStr: Protocol.Types.Datetime): string =>
    dayjs(dateStr).calendar(undefined, {
        sameDay: '[Запись на] HH:mm [(Сегодня)]',
        nextDay: '[Запись на] HH:mm [(Завтра)]',
        nextWeek: '[Запись на] HH:mm (D MMMM)',
        lastDay: '[Запись на] HH:mm (D MMMM)',
        lastWeek: '[Запись на] HH:mm (D MMMM)',
        sameElse: '[Запись на] HH:mm (D MMMM)',
    });

export const showAlternativeCalendarDate = (
    dateStr: Protocol.Types.Datetime
): string =>
    dayjs(dateStr).calendar(undefined, {
        sameDay: '[Онлайн-прием сегодня, в] HH:mm',
        nextDay: '[Онлайн-прием завтра, в] HH:mm',
        nextWeek: '[Онлайн-прием] D MMMM [, в] HH:mm',
        lastDay: '[Онлайн-прием] D MMMM [, в] HH:mm',
        lastWeek: '[Онлайн-прием] D MMMM [, в] HH:mm',
        sameElse: '[Онлайн-прием] D MMMM [, в] HH:mm',
    });

export const foldView = <T>(
    data: T | null | undefined,
    loading: boolean,
    error: null | string
) => (
    emptyNode: () => ReactNode,
    loadingNode: () => ReactNode,
    errorNode: (errorData: typeof error) => ReactNode,
    viewNode: (componentData: T) => ReactNode
): ReactNode => {
    if (loading) return loadingNode();
    if (error) return errorNode(error);
    if (!data || (Array.isArray(data) && data.length === 0)) return emptyNode();
    return viewNode(data);
};
