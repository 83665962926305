import React, { FunctionComponent, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import { Button, notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { pipe } from 'fp-ts/es6/function';
import { findFirst } from 'fp-ts/es6/Array';
import { toNullable } from 'fp-ts/es6/Option';

import { useStore } from 'effector-react';
import {
    AppointmentsStore,
    finishVideoChat,
    startVideoChat,
} from '../../effector/appointments';

import { VideoChat } from './components/VideoChat';
import { UsernameStore } from '../../effector/system';

import { useInterval } from '../../hooks/interval';
import { isUndefined } from '../../utils/guards';
import { showAlternativeCalendarDate } from '../../utils/view';

import VideoInfoIcon from './icons/video-info-icon.svg';
import './styles.less';

type URLParams = { id?: string };
export const TwilioVideo: FunctionComponent = () => {
    const { id } = useParams<URLParams>();
    const { push } = useHistory();

    const loading = useStore(startVideoChat.pending);
    const username = useStore(UsernameStore);
    const dataSource = useStore(AppointmentsStore);
    const currentAppData = useMemo(
        () =>
            pipe(
                dataSource,
                findFirst((h) => h.id.toString() === id),
                toNullable
            ),
        [dataSource, id]
    );

    const [now, setNow] = useState(dayjs());
    useInterval(() => {
        const nextState = dayjs();
        if (!now.isSame(nextState, 'minute')) setNow(nextState);
    }, 1000);

    if (currentAppData === null) return null;

    const startSession = (): void => {
        startVideoChat({ appointment_id: currentAppData.id })
            .then(() =>
                notification.success({
                    message: 'Сессия онлайн консультации начата',
                })
            )
            .catch(() =>
                notification.error({
                    message: 'Ошибка при создании сессии',
                })
            );
    };

    if (
        isUndefined(currentAppData.video_chat_token) ||
        isUndefined(currentAppData.room_name)
    ) {
        const appDate = dayjs(currentAppData.starts_at);
        const canCreateVideoChat = appDate.isSame(now) || appDate.isAfter(now);

        return (
            <div className="VideoInfo">
                <img
                    className="VideoInfo__icon"
                    src={VideoInfoIcon}
                    alt="video info"
                />
                <div className="VideoInfo__title">
                    {showAlternativeCalendarDate(currentAppData.starts_at)}
                </div>
                <div className="VideoInfo__subTitle">
                    Пациент: {currentAppData.patient_name}
                </div>
                <Button
                    className="VideoInfo__createButton"
                    type="primary"
                    size="large"
                    hidden={canCreateVideoChat}
                    loading={loading}
                    onClick={startSession}
                >
                    Начать прием
                </Button>
                <Button
                    className="VideoInfo__close"
                    type="text"
                    size="large"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={() => push('/appointments')}
                />
            </div>
        );
    }

    return (
        <VideoChat
            token={currentAppData.video_chat_token}
            chatName={currentAppData.room_name || ''}
            userName={username || ''}
            participantName={currentAppData.patient_name}
            startSession={startSession}
            onCloseCallback={() => {
                finishVideoChat({
                    appointment_id: currentAppData.id,
                })
                    .then(() =>
                        notification.success({
                            message: 'Сессия онлайн консультации завершена',
                        })
                    )
                    .catch(() =>
                        notification.error({
                            message: 'Ошибка при закрытии сессии',
                        })
                    )
                    .finally(() => push('/appointments'));
            }}
        />
    );
};
