import React, { FunctionComponent, useEffect, useState } from 'react';
import { DateChangeCallBack, DateRangePicker } from 'react-nice-dates';
import { ru } from 'date-fns/locale';
import { format } from 'date-fns';
import clsx from 'clsx';

import './styles.scss';

const dateValueGetter = (date: Date | null): string =>
    date ? format(date, 'dd MMMM yyyy', { locale: ru }) : '';

type Props = {
    itemClassName: string;
    inputClassName: string;
    containerClassName: string;
    onStartChange: DateChangeCallBack;
    onEndChange: DateChangeCallBack;
    startDate: Date | null;
    endDate: Date | null;
};
export const RangeDatePicker: FunctionComponent<Props> = (props) => {
    const [startDate, setStartDate] = useState<Date | null>(props.startDate);
    const [endDate, setEndDate] = useState<Date | null>(props.endDate);

    useEffect(() => {
        props.onStartChange(startDate);
    }, [startDate]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        props.onEndChange(endDate);
    }, [endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <DateRangePicker
            startDate={startDate ?? undefined}
            endDate={endDate ?? undefined}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            minimumDate={new Date()}
            format="dd MMMM yyyy"
            locale={ru}
        >
            {({ startDateInputProps, endDateInputProps }) => (
                <div className={props.containerClassName}>
                    <div className={props.itemClassName}>
                        <input
                            {...startDateInputProps}
                            value={dateValueGetter(startDate)}
                            className={clsx(props.inputClassName, 'ant-input')}
                            placeholder="Дата с"
                            onKeyDown={(evt) => {
                                if (evt.key === 'Backspace') {
                                    setStartDate(null);
                                }
                            }}
                        />
                    </div>
                    <div className={props.itemClassName}>
                        <input
                            {...endDateInputProps}
                            value={dateValueGetter(endDate)}
                            className={clsx(props.inputClassName, 'ant-input')}
                            placeholder="Дата по"
                            onKeyDown={(evt) => {
                                if (evt.key === 'Backspace') {
                                    setEndDate(null);
                                }
                            }}
                        />
                    </div>
                </div>
            )}
        </DateRangePicker>
    );
};
