import React, { FunctionComponent, useMemo } from 'react';
import { DateChangeCallBack } from 'react-nice-dates';

import { format, parse } from 'date-fns';

import { constNull, pipe } from 'fp-ts/es6/function';
import { fold, fromNullable } from 'fp-ts/es6/Option';

import { useStore } from 'effector-react';
import {
    FiltersState,
    FilterStore,
    setFilters,
} from '../../effector/appointments';

import './styles.less';

import { Protocol } from '../../api/protocol';
import { RangeDatePicker } from '../DatePicker';

const convert = (date?: Protocol.Types.Date): Date | null =>
    pipe(
        fromNullable(date),
        fold(constNull, (h) => parse(h, 'yyyy.MM.dd', new Date()))
    );

export const AppointmentsFilters: FunctionComponent = () => {
    const filters = useStore(FilterStore);

    const { startDate, endDate } = useMemo(
        () => ({
            startDate: convert(filters.date_from),
            endDate: convert(filters.date_to),
        }),
        [filters]
    );

    const buildDateChangeHandler = (
        kind: keyof FiltersState
    ): DateChangeCallBack => (date) => {
        const { [kind]: a, ...b } = filters;
        setFilters(
            pipe(
                fromNullable(date),
                fold(
                    () => b,
                    (value) => ({
                        ...b,
                        [kind]: format(
                            value,
                            'yyyy.MM.dd'
                        ) as Protocol.Types.Date,
                    })
                )
            )
        );
    };

    return (
        <div className="AppointmentsFilters">
            <RangeDatePicker
                itemClassName="AppointmentsFilters__item"
                inputClassName="AppointmentsFilters__input"
                containerClassName="AppointmentsFilters__container"
                onEndChange={buildDateChangeHandler('date_to')}
                onStartChange={buildDateChangeHandler('date_from')}
                startDate={startDate}
                endDate={endDate}
            />
        </div>
    );
};
