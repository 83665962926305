import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import { Empty, List, notification } from 'antd';
import { RightOutlined } from '@ant-design/icons/lib';

import { eqStrict, getStructEq } from 'fp-ts/es6/Eq';
import { useStore } from 'effector-react';
import {
    AppointmentsStore,
    fetchAppointments,
    FiltersState,
    FilterStore,
} from '../../effector/appointments';

import './styles.less';

import { isString } from '../../utils/guards';
import { showCalendarDate } from '../../utils/view';

import { useInterval } from '../../hooks/interval';
import { Protocol } from '../../api/protocol';

const getCeilDiffMinutes = (from: Dayjs, to: Dayjs): number =>
    Math.ceil(from.diff(to, 'second') / 60);

const filtersEq = getStructEq<FiltersState>({
    date_to: eqStrict,
    date_from: eqStrict,
});

type URLParams = { id?: string };
export const AppointmentsList: FunctionComponent = () => {
    const { id } = useParams<URLParams>();

    const filters = useStore(FilterStore);
    const loading = useStore(fetchAppointments.pending);
    const dataSource = useStore(AppointmentsStore);

    const [silent, setSilent] = useState(false);
    const [currentFilters, setCurrentFilters] = useState(filters);

    useInterval(() => {
        setSilent(true);
        fetchAppointments(filters)
            .catch((e) =>
                notification.error({
                    message: 'Ошибка',
                    description: e.getCommonFirstMessage(),
                })
            )
            .finally(() => setSilent(false));
    }, 60000);

    useEffect(() => {
        if (!filtersEq.equals(filters, currentFilters)) {
            setCurrentFilters(filters);
            fetchAppointments(filters).catch((e) =>
                notification.error({
                    message: 'Ошибка',
                    description: e.getCommonFirstMessage(),
                })
            );
        }
    }, [filters, currentFilters]);

    const [now, setNow] = useState(dayjs());
    useInterval(() => {
        const nextState = dayjs();
        if (!now.isSame(nextState, 'minute')) setNow(nextState);
    }, 1000);

    const isAppointmentStarted = (
        startsAt: Protocol.Types.Datetime,
        endsAt: Protocol.Types.Datetime
    ): boolean =>
        getCeilDiffMinutes(now, dayjs(startsAt)) >= 0 &&
        getCeilDiffMinutes(now, dayjs(endsAt)) <= 0;

    const getTimeToAppointment = (
        startsAt: Protocol.Types.Datetime,
        endsAt: Protocol.Types.Datetime
    ): string | null => {
        const minutes = getCeilDiffMinutes(dayjs(startsAt), now);
        if (minutes > 0 && minutes <= 60) return `Через ${minutes} мин.`;
        if (isAppointmentStarted(startsAt, endsAt)) return 'Приём начался';
        return null;
    };

    return (
        <div
            className={clsx(
                'AppointmentsList',
                dataSource.length === 0 && 'AppointmentsList--placeholder'
            )}
        >
            <List
                loading={!silent && loading}
                bordered={false}
                dataSource={dataSource}
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="Записи на выбранный период не найдены"
                        />
                    ),
                }}
                renderItem={(item) => {
                    const isActive = isString(id) && id === item.id.toString();
                    const timeToAppointment = getTimeToAppointment(
                        item.starts_at,
                        item.ends_at
                    );
                    return (
                        <List.Item
                            className={clsx(
                                'AppointmentsList__item',
                                isActive && 'AppointmentsList__item--active',
                                getTimeToAppointment(
                                    item.starts_at,
                                    item.ends_at
                                ) && 'AppointmentsList__item--withTime'
                            )}
                        >
                            <Link
                                to={`/appointments/${item.id}`}
                                className="AppointmentsList__itemLink"
                            >
                                <div className="AppointmentsList__card">
                                    <div className="AppointmentsList__cardInfoBlock">
                                        <span className="AppointmentsList__cardTitle">
                                            {showCalendarDate(item.starts_at)}
                                        </span>
                                        <span className="AppointmentsList__cardSubTitle">
                                            {item.patient_name}
                                        </span>
                                        {timeToAppointment && (
                                            <span
                                                className={clsx(
                                                    'AppointmentsList__cardTime',
                                                    isAppointmentStarted(
                                                        item.starts_at,
                                                        item.ends_at
                                                    ) &&
                                                        'AppointmentsList__cardTime--started'
                                                )}
                                            >
                                                {timeToAppointment}
                                            </span>
                                        )}
                                    </div>
                                    <div className="AppointmentsList__cardHoverLink">
                                        <RightOutlined />
                                    </div>
                                </div>
                            </Link>
                        </List.Item>
                    );
                }}
            />
        </div>
    );
};
