import React, { FunctionComponent } from 'react';
import { Header } from '../components/Header';

export const Layout: FunctionComponent = (props) => {
    return (
        <div className="Layout">
            <Header />
            <main className="Layout__main">{props.children}</main>
        </div>
    );
};
