import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

export const TitleComponent: FunctionComponent<{ title: string | null }> = ({
    title = null,
}) => {
    const siteName = process.env.REACT_APP_SITE_NAME;
    const postfix = siteName ? ` | ${siteName}` : null;
    const pageTitle = postfix ? title + postfix : title;
    return (
        <Helmet>
            <title>{pageTitle}</title>
        </Helmet>
    );
};
