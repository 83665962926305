import React, {
    CSSProperties,
    forwardRef,
    FunctionComponent,
    useEffect,
    useRef,
} from 'react';
import {
    LocalAudioTrack,
    LocalVideoTrack,
    RemoteAudioTrack,
    RemoteVideoTrack,
} from 'twilio-video';

import PlaceholderUser from '../icons/placeholder-user.svg';
import PlaceholderDoctor from '../icons/placeholder-doctor.svg';

import StateMicOff from '../icons/state-mic-off.svg';
import StateVideoOff from '../icons/state-video-off.svg';

/* eslint-disable jsx-a11y/media-has-caption */
const Audio = forwardRef<HTMLAudioElement>((props, ref) => (
    <audio ref={ref} style={{ display: 'none' }} />
));
const Video = forwardRef<HTMLVideoElement, { style: CSSProperties }>(
    (props, ref) => (
        <video
            ref={ref}
            style={{
                maxHeight: '100%',
                objectFit: 'cover',
                ...props.style,
            }}
        />
    )
);
/* eslint-enable jsx-a11y/media-has-caption */

export type LocalData = {
    audio: LocalAudioTrack | null;
    video: LocalVideoTrack | null;
};

export type ParticipantData = {
    audio: RemoteAudioTrack | null;
    video: RemoteVideoTrack | null;
};

type VideoElementProps = {
    data: LocalData | ParticipantData | null;
    name: string;
    isMe?: boolean;
    minimize?: boolean;
    videoEnabled: boolean;
    micEnabled: boolean;
};
export const VideoElement: FunctionComponent<VideoElementProps> = (props) => {
    const {
        data,
        name,
        isMe = false,
        minimize = false,
        videoEnabled,
        micEnabled,
    } = props;

    const audioRef = useRef<HTMLAudioElement>(null!);
    const videoRef = useRef<HTMLVideoElement>(null!);

    useEffect(() => {
        const audio = audioRef.current;
        const video = videoRef.current;
        if (data?.audio) data.audio.attach(audio);
        if (data?.video && videoEnabled) data.video.attach(video);

        return () => {
            if (data?.audio) data.audio.detach(audio);
            if (data?.video) data.video.detach(video);
        };
    }, [data, videoEnabled]);

    const style: CSSProperties = isMe ? { transform: 'rotateY(180deg)' } : {};
    if (minimize && isMe) style.objectFit = 'contain';

    const statuses: JSX.Element[] = [];
    if (!videoEnabled)
        statuses.push(<img key="video" src={StateVideoOff} alt="" />);
    if (!micEnabled) statuses.push(<img key="mic" src={StateMicOff} alt="" />);
    const component =
        data && videoEnabled && data?.video !== null ? (
            <>
                <Video ref={videoRef} style={style} />
                <div
                    className={
                        isMe
                            ? 'VideoChat__statusesFixed VideoChat__statusesFixed--small'
                            : 'VideoChat__statusesFixed'
                    }
                >
                    {statuses}
                </div>
            </>
        ) : (
            <div className="VideoChat__placeholder">
                <img src={isMe ? PlaceholderDoctor : PlaceholderUser} alt="" />
                <span>{name}</span>
                {statuses.length > 0 && (
                    <div className="VideoChat__statuses">{statuses}</div>
                )}
            </div>
        );

    const className = `${isMe ? 'VideoChat__me' : 'VideoChat__participant'}${
        minimize ? ' minimize' : ''
    }`;
    return (
        <div className={className}>
            <Audio ref={audioRef} />
            {component}
        </div>
    );
};
