import React, { FunctionComponent, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { CalendarOutlined } from '@ant-design/icons/lib';
import { useInterval } from '../../hooks/interval';
import './styles.less';

export const DateTimeInfo: FunctionComponent = () => {
    const [currentTime, setCurrentTime] = useState(dayjs());
    useInterval(() => {
        setCurrentTime(dayjs());
    }, 1000);
    return (
        <div className="DateTimeInfo">
            <p className="DateTimeInfo__row">
                <CalendarOutlined className="DateTimeInfo__icon" />
                {currentTime.locale('ru').format('HH:mm, D MMMM')}
            </p>
        </div>
    );
};
