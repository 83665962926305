import React, { PureComponent, ComponentType } from 'react';
import { TitleComponent } from '../components/TitleComponent';

export function withTitle<T>(title: string) {
    return function wrappedWithTitle(
        WrappedComponent: ComponentType<T>
    ): ComponentType<T> {
        return class extends PureComponent<T> {
            render() {
                return (
                    <>
                        <TitleComponent title={title} />
                        <WrappedComponent {...this.props} />
                    </>
                );
            }
        };
    };
}
