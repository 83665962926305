import { createDomain } from 'effector';
import withStorage from 'effector-storage';

import { ApiClient, apiLogin, apiLogout } from '../api';
import { ApiError } from '../api/client/errors';
import { Protocol } from '../api/protocol';

export const SystemDomain = createDomain();
const createStorageStore = withStorage(SystemDomain.store);

export const clearSession = SystemDomain.event();

export const updateSession = SystemDomain.effect<
    Protocol.LoginRequest,
    Protocol.LoginResponse,
    ApiError
>();

export const forgotSession = SystemDomain.effect<
    Protocol.LogoutRequest,
    Protocol.LogoutResponse,
    ApiError
>();

updateSession.use(apiLogin);
forgotSession.use(apiLogout);

export type SystemState = {
    loggedIn: boolean;
    name: string | null;
};

const initialState: SystemState = {
    loggedIn: false,
    name: null,
};

const cfg = { key: 'system' };
export const SystemStore = createStorageStore<SystemState>(initialState, cfg)
    .on(updateSession.doneData, (_, { name }) => ({ loggedIn: true, name }))
    .reset(clearSession, forgotSession.done);

export const UsernameStore = SystemStore.map((h) => h.name);

ApiClient.setInvalidTokenHandler(() => clearSession());
