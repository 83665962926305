import React, { FunctionComponent } from 'react';

import { App } from '../App';

export const Root: FunctionComponent = () => {

    return (
        <App />
    );
};
