import React, { FunctionComponent, useState } from 'react';
import { Button, Modal } from 'antd';

import MicNotFound from '../icons/mic-not-found.svg';
import SessionExpire from '../icons/session-expire.svg';
import CameraNotFound from '../icons/camera-not-found.svg';

export type KnownErrors = 'audio' | 'video' | 'session' | 'unknown' | null;

const reloadPage = (): void => window.location.reload();

const askPermission = (mediaType: MediaPermission): void => {
    const modalTitle =
        mediaType === 'audio'
            ? 'Микрофон не найден. Убедитесь, что микрофон подключен'
            : 'Камера не найдена. Убедитесь, что камера подключена';

    navigator.mediaDevices
        .getUserMedia({ [mediaType]: true })
        .then(reloadPage)
        .catch(
            () =>
                new Promise<void>((resolve) => {
                    Modal.warning({
                        title: modalTitle,
                        okText: 'Закрыть',
                        onOk: () => resolve(),
                    });
                })
        );
};

type MediaPermission = 'audio' | 'video';
type Props = {
    type: KnownErrors;
    startSession: () => Promise<void>;
    onClose: () => void;
};
export const ShowError: FunctionComponent<Props> = ({
    type,
    startSession,
    onClose,
}) => {
    const [loading, setLoading] = useState(false);

    if (type === 'session')
        return (
            <div className="VideoChat__placeholder fadeIn">
                <img src={SessionExpire} alt="" />
                <span className="VideoChat__placeholderHeader">
                    Сессия видеочата истекла
                </span>
                <div className="VideoChat__placeholderButtons">
                    <Button
                        type="primary"
                        className="VideoChat__placeholderAskButton"
                        loading={loading}
                        onClick={() => {
                            setLoading(true);
                            onClose();
                        }}
                    >
                        Закрыть запись
                    </Button>
                </div>
            </div>
        );

    if (type === 'unknown')
        return (
            <div className="VideoChat__placeholder fadeIn">
                <img src={SessionExpire} alt="" />
                <span className="VideoChat__placeholderHeader">
                    Ошибка инициализации видеочата
                </span>
            </div>
        );

    const header =
        type === 'audio' ? 'Нет доступа к микрофону' : 'Нет доступа к камере';
    const description =
        type === 'audio'
            ? 'Пациент не сможет вас услышать, нужно дать программе доступ к микрофону'
            : 'Можно начать консультацию, но пациент не сможет вас увидеть';
    const icon = type === 'audio' ? MicNotFound : CameraNotFound;
    return (
        <div className="VideoChat__placeholder fadeIn">
            <img src={icon} alt="" />
            <span className="VideoChat__placeholderHeader">{header}</span>
            <span className="VideoChat__placeholderDescription">
                {description}
            </span>
            {type === 'video' && (
                <div className="VideoChat__placeholderButtons">
                    <Button
                        type="primary"
                        className="VideoChat__placeholderAskButton"
                        loading={loading}
                        onClick={() => {
                            askPermission('video');
                        }}
                    >
                        Проверить доступ к камере
                    </Button>

                    <Button
                        type="primary"
                        className="VideoChat__placeholderAskButton VideoChat__placeholderAskButton--hollow"
                        loading={loading}
                        onClick={() => {
                            setLoading(true);
                            startSession().finally(() => setLoading(false));
                        }}
                    >
                        Начать консультацию
                    </Button>
                </div>
            )}
            {type === 'audio' && (
                <Button
                    onClick={() => {
                        askPermission('audio');
                    }}
                    type="primary"
                    className="VideoChat__placeholderAskButton"
                >
                    Проверить доступ к микрофону
                </Button>
            )}
        </div>
    );
};
