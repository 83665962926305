import React, { FunctionComponent } from 'react';

import { pipe } from 'fp-ts/es6/function';

import { withTitle } from '../../containers/WithTitle';
import { Layout } from '../../containers/Layout';

import { AppointmentsFilters } from '../../components/AppointmentsFilters';
import { AppointmentsList } from '../../components/AppointmentsList';
import { TwilioVideo } from '../../components/TwilioVideo';

import './styles.less';

const Appointments: FunctionComponent = () => {
    return (
        <Layout>
            <section className="Appointments">
                <aside className="Appointments__list">
                    <AppointmentsFilters />
                    <AppointmentsList />
                </aside>
                <main className="Appointments__videoChat">
                    <TwilioVideo />
                </main>
            </section>
        </Layout>
    );
};

const Component = pipe(Appointments, withTitle('Мои записи'));
export { Component as Appointments };
