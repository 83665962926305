import { useEffect, useRef } from 'react';

const noop = (): void => {};

export function useInterval(
    callback: typeof noop,
    delay: number,
    immediate = false
): void {
    const savedCallback = useRef<typeof noop>();

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (immediate) {
            console.log('log');
            callback();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Set up the interval.
    useEffect(() => {
        function tick(): void {
            if (savedCallback.current !== undefined) {
                savedCallback.current();
            }
        }

        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }

        return () => noop();
    }, [delay]);
}
