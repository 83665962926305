import React, { FunctionComponent } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from 'rc-field-form/lib/interface';
import { Button, Form, Input, notification } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons/lib';

import { pipe } from 'fp-ts/es6/function';

import { useStore } from 'effector-react';
import { Protocol } from '../../api/protocol';
import { updateSession } from '../../effector/system';

import { FeaturedLayout } from '../../containers/FeaturedLayout';
import { withTitle } from '../../containers/WithTitle';

import './styles.less';

const Login: FunctionComponent = () => {
    const loading = useStore(updateSession.pending);
    const [form] = Form.useForm();

    // Form handler
    const onFinish = (values: Store): void => {
        const payload = values as Protocol.LoginRequest;
        updateSession(payload).catch((e) => {
            notification.error({
                message: 'Ошибка',
                description: e.getCommonFirstMessage(),
            });
        });
    };

    return (
        <FeaturedLayout>
            <Form form={form} className="LoginForm" onFinish={onFinish}>
                {/* -----Title----- */}
                <h3 className="LoginForm__title">Кабинет врача</h3>

                {/* -----Login----- */}
                <Form.Item
                    name="login"
                    className="LoginForm__inputContainer"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите логин',
                        },
                    ]}
                >
                    <Input
                        className="LoginForm__input"
                        size="large"
                        placeholder="Логин"
                    />
                </Form.Item>

                {/* -----Password----- */}
                <Form.Item
                    name="password"
                    className="LoginForm__inputContainer"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите пароль',
                        },
                    ]}
                >
                    <Input.Password
                        className="LoginForm__input"
                        type="password"
                        size="large"
                        placeholder="Пароль"
                        iconRender={(visible) =>
                            visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                        }
                    />
                </Form.Item>

                {/* -----Submit----- */}
                <Button
                    htmlType="submit"
                    className="LoginForm__submit"
                    type="primary"
                    size="large"
                    loading={loading}
                >
                    Войти
                </Button>
            </Form>
        </FeaturedLayout>
    );
};

const component = pipe(Login, withTitle('Личный кабинет врача'));
export { component as Login };
