import { Protocol } from './protocol';
import { CookieAuth } from './client/auth';
import { ApiClientClass } from './client';

export const ApiClient = new ApiClientClass(CookieAuth());

// ----- Methods -----
/* eslint-disable @typescript-eslint/explicit-function-return-type */

export const apiLogin = (
    payload: Protocol.LoginRequest
): Promise<Protocol.LoginResponse> =>
    ApiClient.post('/api/doctor/login', payload);

export const apiLogout = (): Promise<Protocol.LogoutResponse> =>
    ApiClient.post('/api/doctor/logout');

export const apiAppointmentsList = (
    payload: Protocol.AppointmentDoctorListRequest = {}
): Promise<Protocol.AppointmentDoctorListResponse> =>
    ApiClient.get('/api/appointment/doctor_list', payload);

export const apiStartVideoChat = (
    payload: Protocol.AppointmentStartVideoChatRequest
): Promise<Protocol.AppointmentStartVideoChatResponse> =>
    ApiClient.post('/api/appointment/start_video_chat', payload);

export const apiFinishVideoChat = (
    payload: Protocol.AppointmentFinishVideoChatRequest
): Promise<Protocol.AppointmentFinishVideoChatResponse> =>
    ApiClient.post('/api/appointment/finish_video_chat', payload);

/* eslint-enable @typescript-eslint/explicit-function-return-type */
