import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
    connect,
    LocalAudioTrack,
    LocalVideoTrack,
    createLocalAudioTrack,
    createLocalVideoTrack,
    Room,
    RemoteParticipant,
    RemoteTrack,
    LocalTrack,
} from 'twilio-video';

import { Modal } from 'antd';

import { sequenceT } from 'fp-ts/lib/Apply';
import * as TE from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/Either';

import { Loader } from '../../Loader';
import { ShowError, KnownErrors } from './ShowError';
import { VideoElement, ParticipantData, LocalData } from './VideoElement';

import ControlMicOn from '../icons/control-mic-on.svg';
import ControlMicOff from '../icons/control-mic-off.svg';
import ControlVideoOn from '../icons/control-video-on.svg';
import ControlVideoOff from '../icons/control-video-off.svg';
import ControlPhoneDown from '../icons/control-phone-down.svg';

const VideoUnavailableCaption =
    'Камера не доступна. Можно провести онлайн консультацию без камеры. Пациент будет вас слышать, но не будет видеть. Начать консультацию без камеры?';
const CloseChatCaption = 'Точно завершить онлайн консультацию?';

/* eslint-disable prefer-promise-reject-errors */

type VideoChatProps = {
    token: string;
    chatName: string;
    userName: string;
    participantName: string;
    onCloseCallback: () => void;
    startSession: () => void;
};

const getLocalAudioTask = TE.tryCatch<KnownErrors, LocalAudioTrack>(
    () => createLocalAudioTrack(),
    () => 'audio'
);

const getLocalVideoTask = TE.tryCatch<KnownErrors, LocalVideoTrack | null>(
    () => createLocalVideoTrack({ width: 1024 }),
    () => null
);

const confirmOnlyVideoTask = TE.tryCatch<KnownErrors, LocalVideoTrack | null>(
    () =>
        new Promise<null>((resolve, reject) => {
            Modal.confirm({
                title: VideoUnavailableCaption,
                okText: 'Да',
                cancelText: 'Отменить',
                onOk: () => resolve(null),
                onCancel: () => reject('video'),
            });
        }),
    (r) => r as KnownErrors
);

const getLocalVideoAndConfirmTask = pipe(
    getLocalVideoTask,
    TE.orElse(() => confirmOnlyVideoTask)
);

const subscribeTrack = (
    track: RemoteTrack,
    setter: (value: boolean) => void
): void => {
    track.on('enabled', () => setter(true));
    track.on('disabled', () => setter(false));
};

export const VideoChat: FunctionComponent<VideoChatProps> = (props) => {
    const [localData, setLocalData] = useState<LocalData | null>(null);
    const [
        participantData,
        setParticipantData,
    ] = useState<ParticipantData | null>(null);

    const [showControls, setShowControls] = useState(false);
    const [videoEnabled, setVideoState] = useState(true);
    const [micEnabled, setMicState] = useState(true);
    const [participantVideoEnabled, setParticipantVideoEnabled] = useState(
        true
    );
    const [participantMicEnabled, setParticipantMicEnabled] = useState(true);

    const [error, setError] = useState<KnownErrors | null>(null);
    const [loading, setLoading] = useState(true);
    const [
        disconnectCallback,
        setDisconnectCallback,
    ] = useState(() => () => {});

    const buildProgram = (withoutVideo = false): Promise<void> => {
        setLoading(true);
        setError(null);

        const program = pipe(
            sequenceT(TE.taskEither)(
                getLocalAudioTask,
                withoutVideo
                    ? TE.of<KnownErrors, LocalVideoTrack | null>(null)
                    : getLocalVideoAndConfirmTask
            ),
            TE.chain(([audioTrack, videoTrack]) => {
                setLocalData({ audio: audioTrack, video: videoTrack });

                const tracks: LocalTrack[] = [];
                if (audioTrack) tracks.push(audioTrack);
                if (videoTrack) tracks.push(videoTrack);

                return TE.tryCatch(
                    () => connect(props.token, { tracks }),
                    (reason) => {
                        if (reason instanceof Error) {
                            if (reason.message === 'Room not found') {
                                return 'session' as KnownErrors;
                            }
                        }

                        return 'unknown' as KnownErrors;
                    }
                );
            }),
            TE.map((room) => {
                const { audio, video } = localData || {};

                setLoading(false);
                setDisconnectCallback(() => () => {
                    room.disconnect();
                    audio?.stop();
                    video?.stop();
                    setParticipantData(null);
                });

                const setParticipant = (
                    participant: RemoteParticipant
                ): void => {
                    participant.on('trackSubscribed', (track: RemoteTrack) => {
                        if (track.kind === 'audio') {
                            subscribeTrack(track, setParticipantMicEnabled);
                            setParticipantMicEnabled(track.isEnabled);
                            setParticipantData((h) => ({
                                video: h?.video || null,
                                audio: track,
                            }));
                        } else if (track.kind === 'video') {
                            subscribeTrack(track, setParticipantVideoEnabled);
                            setParticipantVideoEnabled(track.isEnabled);
                            setParticipantData((h) => ({
                                audio: h?.audio || null,
                                video: track,
                            }));
                        }
                    });

                    participant.on(
                        'trackUnsubscribed',
                        (track: RemoteTrack) => {
                            if (track.kind === 'audio') {
                                track.removeAllListeners();
                                setParticipantData((h) => ({
                                    video: h?.video || null,
                                    audio: null,
                                }));
                            } else if (track.kind === 'video') {
                                track.removeAllListeners();
                                setParticipantData((h) => ({
                                    audio: h?.audio || null,
                                    video: null,
                                }));
                            }
                        }
                    );
                };

                const forgetParticipant = (): void => setParticipantData(null);

                room.participants.forEach(setParticipant);
                room.on('participantConnected', setParticipant) // TODO: Добавить звук/сообщение
                    .on('participantDisconnected', forgetParticipant) // TODO: Добавить звук/сообщение
                    .on('disconnect', (eventRoom: Room) => {
                        eventRoom.disconnect();
                    });

                return null;
            })
        );

        const destroySession = (err: KnownErrors): void => {
            setError(err);
            setLoading(false);
            disconnectCallback();
        };

        return program()
            .then(fold(destroySession, () => {}))
            .catch(() => destroySession('unknown'));
    };

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        buildProgram();
        return disconnectCallback;
    }, [props.token]); // eslint-disable-line react-hooks/exhaustive-deps

    const timer = useRef<number>();
    const handleMouseMove = (): void => {
        setShowControls(true);
        clearTimeout(timer.current);
        timer.current = window.setTimeout(() => {
            setShowControls(false);
        }, 3000);
    };

    const changeMicState = (): void => {
        if (localData === null) return;
        const audioTrack = localData?.audio;
        if (audioTrack === null) return;
        if (micEnabled) audioTrack.disable();
        else audioTrack.enable();
        setMicState(!micEnabled);
        handleMouseMove();
    };

    const changeVideoState = (): void => {
        if (localData === null) return;
        const videoTrack = localData.video;
        if (videoTrack === null) return;
        if (videoEnabled) videoTrack.disable();
        else videoTrack.enable();
        setVideoState(!videoEnabled);
        handleMouseMove();
    };

    const onClose = (): void => {
        disconnectCallback();
        queueMicrotask(props.onCloseCallback);
    };

    const closeChat = (): void => {
        Modal.confirm({
            title: CloseChatCaption,
            okText: 'Да',
            cancelText: 'Отменить',
            onOk: onClose,
        });
    };

    useEffect(() => () => disconnectCallback(), [disconnectCallback]);

    if (loading)
        return (
            <div className="VideoChat full">
                <Loader />
            </div>
        );

    if (error)
        return (
            <div className="VideoChat full">
                <ShowError
                    type={error}
                    startSession={() => buildProgram(true)}
                    onClose={onClose}
                />
            </div>
        );

    return (
        <div className="VideoChat" onMouseMove={handleMouseMove}>
            <VideoElement
                data={localData}
                isMe
                name={props.userName}
                videoEnabled={videoEnabled}
                micEnabled={micEnabled}
                minimize={participantData !== null}
            />
            <VideoElement
                data={participantData}
                name={props.participantName}
                videoEnabled={participantVideoEnabled}
                micEnabled={participantMicEnabled}
            />
            <div
                className={
                    showControls
                        ? 'VideoChat__controls shown'
                        : 'VideoChat__controls'
                }
            >
                <button onClick={changeMicState} type="button">
                    <img
                        alt="mic icon"
                        src={micEnabled ? ControlMicOn : ControlMicOff}
                    />
                </button>
                {localData?.video !== null && (
                    <button onClick={changeVideoState} type="button">
                        <img
                            alt="video icon"
                            src={
                                videoEnabled ? ControlVideoOn : ControlVideoOff
                            }
                        />
                    </button>
                )}
                <button onClick={closeChat} type="button">
                    <img src={ControlPhoneDown} alt="hangup icon" />
                </button>
            </div>
        </div>
    );
};

/* eslint-enable prefer-promise-reject-errors */
