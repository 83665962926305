import React, { FunctionComponent } from 'react';
import logoPath from '../../assets/logo.svg';
import './styles.less';

export const FeaturedLayout: FunctionComponent = ({ children }) => {
    return (
        <section className="FeaturedLayout">
            <img className="FeaturedLayout__logo" src={logoPath} alt="" />
            {children}
        </section>
    );
};
